/* global mixpanelIdentity */
/* global mixpanelToken */

import mixpanel from 'mixpanel-browser';

export default class Analytics {
  static defaultEventPrefix = 'QUOTE_ROOT';

  static trackEvent(context, event, properties = {}) {
    Analytics._track([context, event], properties);
  }

  static trackViewEvent(context, properties = {}) {
    Analytics._track([context, 'VIEWED'], properties);
  }

  static trackClickEvent(context, clickContext, properties = {}) {
    Analytics._track([context, clickContext, 'CLICKED'], properties);
  }

  static alias(userId) {
    mixpanel.alias(userId);
  }

  static getDistinctId() {
    if (typeof window !== 'undefined') {
      Analytics.initMixpanel();
      return mixpanel.get_distinct_id();
    }
    return 'some-distinct-id';
  }

  static initMixpanel() {
    if (!mixpanelToken) { return false; }

    mixpanel.init(mixpanelToken);

    if (mixpanelIdentity) { mixpanel.identify(mixpanelIdentity); }
    return true;
  }

  static register(properties) {
    if (!Analytics.initMixpanel()) { return; }

    mixpanel.register(properties);
  }

  static _track(contexts, { eventPrefix = Analytics.defaultEventPrefix, ...properties } = {}) {
    const eventName = Analytics._getEventName(contexts, eventPrefix);

    if (!Analytics.initMixpanel()) { return; }

    mixpanel.track(eventName, {
      ...properties,
    });
  }

  static _getEventName(contexts, eventPrefix) {
    const eventName = [...contexts].filter((context) => context);
    return [eventPrefix].concat(...eventName).join('_').toUpperCase();
  }
}

window.Analytics = Analytics;
